<template>
  <div>
    <new-appointment-sidebar />
    <b-card-header class="pb-50">
      <div class="d-flex justify-content-start">
        <h5>Filtros</h5>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="openNewAppointmentSidebar(undefined)"
        >
          <feather-icon icon="PlusCircleIcon" class="mr-50" />
          <span class="text-nowrap">Novo compromisso</span>
        </b-button>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-1">
          <dynamic-select
            id="customerName"
            label="Nome do cliente"
            placeholder="Digite o nome do cliente"
            v-model="customer"
            :options="customers"
            :loading="loading.customers"
            @find="findCustomers"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Período" label-for="searchRangeDate">
            <flat-pickr
              id="searchRangeDate"
              v-model="rangeDate"
              class="form-control"
              :config="flatPickrConfig"
              :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <dynamic-select
            id="searchRecommendedBy"
            label="Recomendado por"
            placeholder="Digite o nome do cliente"
            v-model="recommendedBy"
            :options="customers"
            :loading="loading.recommendedBy"
            @find="findCustomers"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Tipo de agendamento" label-for="searchEventType">
            <v-select
              id="searchEventType"
              multiple
              v-model="eventType"
              :reduce="(event_type) => event_type.id"
              :options="eventTypes"
              :loading="loading.eventTypes"
              label="description"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Parceiro de negócio (PN)"
            label-for="searchConsultant"
          >
            <v-select
              id="searchConsultant"
              multiple
              v-model="consultant"
              :reduce="(consultant_list) => consultant_list.id"
              :options="consultantList"
              :loading="loading.consultantList"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
              @input="consultantFranchiseChange"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Franqueado" label-for="searchFranchise">
            <v-select
              id="searchFranchise"
              multiple
              v-model="franchise"
              :reduce="(franchise_list) => franchise_list.id"
              :options="franchises"
              :loading="loading.franchises"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
              @input="consultantFranchiseChange"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Tipo de visualização"
            label-for="searchStructureOption"
          >
            <v-select
              id="searchStructureOption"
              v-model="structureOption"
              :reduce="(structure_list) => structure_list.key"
              :options="structures"
              :loading="loading.structures"
              label="name"
              @keyup.enter="search"
              :disabled="hasConsultantFranchiseSelected"
              :clearable="false"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Renda">
            <vue-slider
              class="mt-2 primary"
              v-model="incomeBracket"
              :max="20000"
              :direction="dir"
            >
              <template v-slot:tooltip="{ value }">
                <div v-if="value <= 19999" class="custom-tooltip">
                  {{ value | toCurrency }}
                </div>
                <div v-else class="custom-tooltip">
                  >{{ value | toCurrency }}
                </div>
              </template>
            </vue-slider>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="text-right"> </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormRadioGroup,
  BFormRadio,
  BButton,
  BSpinner,
  BPopover,
  BCardText,
} from "bootstrap-vue";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import NewAppointmentSidebar from "./NewAppointmentSidebar";
import VueSlider from "vue-slider-component";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import { OWN, STRUCTURE } from "@/constants/structure_options";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BSpinner,
    vSelect,
    BCardText,
    BPopover,
    DynamicSelect,
    VueSlider,
    flatPickr,
    NewAppointmentSidebar,
  },
  props: {
    search: Function,
    spinnerActive: Boolean,
    openNewAppointmentSidebar: Function,
  },
  data() {
    return {
      loading: {
        customers: false,
        eventTypes: false,
        consultantList: false,
        franchises: false,
        recommendedBy: false,
        structures: false,
      },
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      customer: undefined,
      rangeDate: undefined,
      recommendedBy: undefined,
      eventType: undefined,
      consultant: undefined,
      franchise: undefined,
      structureOption: OWN,
      incomeBracket: [0, 20000],
      dir: "ltr",
    };
  },
  setup() {
    const actualYear = new Date().getFullYear()
    return { toast: useToast(), actualYear };
  },
  computed: {
    ...mapGetters({
      eventTypes: types.APPOINTMENT_EVENT_TYPES,
      customers: sharedTypes.CUSTOMERS,
      consultantList: sharedTypes.CONSULTANTS,
      franchises: sharedTypes.FRANCHISES,
      structures: sharedTypes.STRUCTURE_TYPES,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    hasConsultantFranchiseSelected: function () {
      return (
        (this.consultant && this.consultant.length > 0) ||
        (this.franchise && this.franchise.length > 0)
      );
    },
  },
  mounted() {
    this.loading.eventTypes = true;
    this.getEventTypes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os tipos de compromisso para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.eventTypes = false;
      });
    this.loading.consultantList = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os parceiros de negócio para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultantList = false;
      });
    this.loading.franchises = true;
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as franquias para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false;
      });
    this.loading.structures = true;
    this.getStructures()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as estruturas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.structures = false;
      });
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.rangeDate =
        this.$route.query.startDate + " até " + this.$route.query.endDate;
      this.search();
    }
  },
  methods: {
    ...mapActions({
      getEventTypes: types.GET_APPOINTMENT_EVENT_TYPES,
      getCustomers: sharedTypes.GET_CUSTOMERS,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getFranchises: sharedTypes.GET_FRANCHISES,
      getStructures: sharedTypes.GET_STRUCTURE_TYPES,
    }),
    findCustomers(keyword) {
      this.loading.recommendedBy = true;
      this.getCustomers({ keyword })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os clientes para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.recommendedBy = false;
        });
    },
    consultantFranchiseChange() {
      if (
        (this.consultant && this.consultant.length > 0) ||
        (this.franchise && this.franchise.length > 0)
      ) {
        this.structureOption = STRUCTURE;
      }
    },
  },
};
</script>

<style lang="scss">
$themeColor: #f26237;
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-slider-component/lib/theme/default.scss";
.form-group {
  margin-bottom: 0;
}
</style>
